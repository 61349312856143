import React, {useEffect, useRef, useState} from "react";
import { AlertCircle, ShoppingCart, Zap, Twitter, Check } from 'lucide-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import logo from "./assets/appicon.png";

const stripePromise = loadStripe('pk_live_51Q9A06KoZILqT2xD8RnxyPU2ivQHGedN3ON5t7o6UqSBf2tYaDVWBi0xyBWqH6I6dIGlzx6LHGRCDGPncTJS2G95000HF9Oz5P');

const PaymentForm = ({ email, plan, onSuccess, onError }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) return;

        setLoading(true);
        try {
            // First submit the payment form elements
            const { error: submitError } = await elements.submit();
            if (submitError) {
                throw new Error(submitError.message);
            }

            const setupResponse = await fetch("https://jupara.xyz/api/waitlist/create", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email,
                    name: email.split('@')[0], // Using email username as name
                }),
            });

            if (!setupResponse.ok) {
                throw new Error('Failed to create setup intent');
            }

            const body = await setupResponse.json()

            const returnUrl = new URL(window.location.href);
            returnUrl.searchParams.set('email', email);
            returnUrl.searchParams.set('plan', plan);

            // Confirm the setup
            const result = await stripe.confirmSetup({
                clientSecret: body.object.client_secret,
                elements,
                confirmParams: {
                    return_url: returnUrl,
                },
            });

            if (result.error) throw new Error(result.error.message);
        } catch (error) {
            onError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <PaymentElement />
            <button
                type="submit"
                disabled={!stripe || loading}
                className={`w-full px-6 py-3 rounded-full text-lg font-semibold transition duration-300
          ${loading ? 'bg-gray-400' : 'bg-purple-600 hover:bg-purple-500'} text-white`}
            >
                {loading ? 'Processing...' : 'Join Waitlist'}
            </button>
        </form>
    );
};

function App() {
    const [basicEmail, setBasicEmail] = useState("");
    const [premiumEmail, setPremiumEmail] = useState("");
    const [basicSubmitted, setBasicSubmitted] = useState(false);
    const [premiumSubmitted, setPremiumSubmitted] = useState(false);
    const [basicError, setBasicError] = useState("");
    const [premiumError, setPremiumError] = useState("");
    const [showPayment, setShowPayment] = useState({ show: false, email: '', plan: null });
    const pricingSectionRef = useRef(null);

    useEffect(() => {
        console.log("PaymentForm useEffect");
        const params = new URLSearchParams(window.location.search);
        if (params.has('setup_intent')) {
            completeSetup(params).then(r => r);
        }
    }, []);

    const completeSetup = async (params) => {
        const stripe = await loadStripe('pk_live_51Q9A06KoZILqT2xD8RnxyPU2ivQHGedN3ON5t7o6UqSBf2tYaDVWBi0xyBWqH6I6dIGlzx6LHGRCDGPncTJS2G95000HF9Oz5P');
        const clientSecret = params.get('setup_intent_client_secret');
        const setupIntentId = params.get('setup_intent');
        const email = params.get('email');
        const plan = params.get('plan');
        console.log('Completing setup:', { setupIntentId, clientSecret });

        try {
            const { setupIntent } = await stripe.retrieveSetupIntent(clientSecret);

            if (setupIntent.status === 'succeeded') {
                const waitlistResponse = await fetch("https://jupara.xyz/api/waitlist/new", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        email: email,
                        type: parseInt(plan),
                        customer_id: "",
                        intent_id: setupIntentId,
                        payment_method: setupIntent.payment_method
                    }),
                });

                if (!waitlistResponse.ok) throw new Error('Failed to submit waitlist entry');
                handlePaymentSuccess(parseInt(plan));
            }
        } catch (error) {
            handlePaymentError(error.message, parseInt(plan));
        }
    };

    const handleInitialSubmit = async (email, plan) => {
        const setError = plan === 0 ? setBasicError : setPremiumError;

        setError(""); // Clear any previous errors

        if (!validateEmail(email)) {
            setError("Please enter a valid email address.");
            return;
        }

        // Show the payment form
        setShowPayment({ show: true, email, plan });
    };

    const handlePaymentSuccess = (plan) => {
        if (plan === 0) {
            setBasicSubmitted(true);
        } else {
            setPremiumSubmitted(true);
        }
        setShowPayment({ show: false, email: '', plan: null });
    };

    const handlePaymentError = (error, plan) => {
        const setError = plan === 0 ? setBasicError : setPremiumError;
        setError(error);
        setShowPayment({ show: false, email: '', plan: null });
    };

    // Your existing helper functions...
    const scrollToPricing = () => {
        pricingSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const renderSignupForm = (plan, email, setEmail, submitted, error, setError) => (
        <div className="mt-8">
            <input
                type="email"
                placeholder="Enter your email"
                className={`w-full px-4 py-2 rounded-full mb-2 ${submitted ? 'bg-gray-200 text-gray-500' : 'text-black'}
          ${error && !submitted ? 'border-2 border-red-500' : ''}`}
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value);
                    setError("");
                }}
                disabled={submitted}
            />
            {error && <p className="text-red-500 mb-2">{error}</p>}
            {!showPayment.show || showPayment.plan !== plan ? (
                <button
                    onClick={() => handleInitialSubmit(email, plan)}
                    className={`w-full px-6 py-3 rounded-full text-lg font-semibold transition duration-300 flex items-center justify-center
            ${submitted
                        ? 'bg-green-500 hover:bg-green-400'
                        : validateEmail(email)
                            ? 'bg-purple-600 hover:bg-purple-500'
                            : 'bg-gray-400 cursor-not-allowed'
                    } text-white`}
                    disabled={submitted || !validateEmail(email)}
                >
                    {submitted ? <Check className="mr-2" size={24}/> : null}
                    {submitted ? 'Joined' : 'Join Waitlist'}
                </button>
            ) : (
                <Elements stripe={stripePromise} options={{
                    mode: 'setup',
                    currency: 'usd',
                    appearance: { theme: 'stripe' },
                }}>
                    <PaymentForm
                        email={email}
                        plan={plan}
                        onSuccess={() => handlePaymentSuccess(plan)}
                        onError={(error) => handlePaymentError(error, plan)}
                    />
                </Elements>
            )}
        </div>
    );
    // TODO: add to dev deps @babel/plugin-proposal-private-property-in-object

    return (
        <div className="min-h-screen bg-gradient-to-b from-purple-950 to-black text-purple-100">
            <header className="bg-purple-990 shadow-md">
                <div className="container mx-auto px-4 py-6 flex justify-between items-center">
                    <div className="flex items-center">
                        <img src={logo} alt="Jupara Logo" className="h-12 w-auto mr-3"/>
                        <h1 className="text-3xl font-bold text-purple-100">Jupara Freebies</h1>
                    </div>
                    <nav>
                        <ul className="flex space-x-4">
                            <li><a href="#features" className="text-purple-200 hover:text-white">Features</a></li>
                            <li>
                                <button onClick={scrollToPricing} className="text-purple-200 hover:text-white">Pricing
                                </button>
                            </li>
                            <li><a href="#contact" className="text-purple-200 hover:text-white">Contact</a></li>
                        </ul>
                    </nav>
                </div>
            </header>

            <main className="container mx-auto px-4 py-12">
                <section className="text-center mb-16">
                    <h2 className="text-4xl font-bold mb-4 text-purple-100">Get Unbeatable Deals Automated</h2>
                    <p className="text-xl text-purple-200 mb-8">Jupara finds and orders heavily discounted products for
                        you,
                        saving you time and money.</p>
                    <button
                        className="bg-purple-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-purple-500 transition duration-300"
                        onClick={scrollToPricing}
                    >
                        Join Waitlist
                    </button>
                </section>

                <section id="features" className="mb-16">
                    <h3 className="text-3xl font-bold mb-8 text-center text-purple-100">How It Works</h3>
                    <div className="grid md:grid-cols-3 gap-8">
                        <div className="bg-purple-800 p-6 rounded-lg shadow-md">
                            <ShoppingCart className="w-12 h-12 text-blue-300 mb-4"/>
                            <h4 className="text-xl font-semibold mb-2 text-purple-100">Automated Orders</h4>
                            <p className="text-purple-200">Our AI handles the entire order process from start to finish
                                so you don't
                                have to lift a finger.</p>
                        </div>
                        <div className="bg-purple-800 p-6 rounded-lg shadow-md">
                            <Zap className="w-12 h-12 text-blue-300 mb-4"/>
                            <h4 className="text-xl font-semibold mb-2 text-purple-100">Instant Discounts</h4>
                            <p className="text-purple-200">Get access to exclusive discounts and flash sales before
                                they're
                                gone.</p>
                        </div>
                        <div className="bg-purple-800 p-6 rounded-lg shadow-md">
                            <AlertCircle className="w-12 h-12 text-blue-300 mb-4"/>
                            <h4 className="text-xl font-semibold mb-2 text-purple-100">Smart Alerts</h4>
                            <p className="text-purple-200">Receive notifications throughout the order process and live
                                updates from
                                our AI.</p>
                        </div>
                    </div>
                </section>

                <section id="pricing" className="mb-16" ref={pricingSectionRef}>
                    <h3 className="text-3xl font-bold mb-8 text-center text-purple-200">Choose Your Beta Plan</h3>
                    <div className="grid md:grid-cols-2 gap-8">
                        <div className="bg-purple-800 p-8 rounded-lg shadow-md border border-purple-600">
                            <h4 className="text-2xl font-semibold mb-4 text-purple-200">Basic Membership</h4>
                            <p className="text-4xl font-bold mb-4 text-purple-300">$75<span
                                className="text-xl font-normal text-purple-400"> Initial + </span></p>
                            <p className="text-4xl font-bold mb-4 text-purple-300">$55<span
                                className="text-xl font-normal text-purple-400">/month</span></p>
                            <ul className="mb-8 text-purple-300">
                                <li className="mb-2">✓ Automated order placement</li>
                                <li className="mb-2">✓ Access to daily deals</li>
                                <li className="mb-2">✓ 30 Account Limit</li>
                                <li className="mb-2">✓ Product restock module</li>
                            </ul>
                            {renderSignupForm(0, basicEmail, setBasicEmail, basicSubmitted, basicError, setBasicError)}
                        </div>
                        <div className="bg-purple-700 p-8 rounded-lg shadow-md border border-purple-500">
                            <h4 className="text-2xl font-semibold mb-4 text-purple-100">Premium Membership</h4>
                            <p className="text-4xl font-bold mb-4 text-purple-200">$100<span
                                className="text-xl font-normal text-purple-300"> Initial + </span></p>
                            <p className="text-4xl font-bold mb-4 text-purple-200">$75<span
                                className="text-xl font-normal text-purple-300">/month</span></p>
                            <ul className="mb-8 text-purple-200">
                                <li className="mb-2">✓ All Basic features</li>
                                <li className="mb-2">✓ Priority access to limited-time offers</li>
                                <li className="mb-2">✓ 50 Account Limit</li>
                                <li className="mb-2">✓ Advanced AI-powered modules</li>
                            </ul>
                            {renderSignupForm(1, premiumEmail, setPremiumEmail, premiumSubmitted, premiumError, setPremiumError)}
                        </div>
                    </div>
                </section>

                <section id="contact" className="text-center">
                    <h3 className="text-3xl font-bold mb-4 text-purple-200">Want to Learn More?</h3>
                    <p className="text-xl text-purple-300 mb-8">Follow us on Twitter for the latest updates and deal
                        alerts!</p>
                    <a
                        href="https://twitter.com/JuparaFreebies"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center bg-purple-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-purple-500 transition duration-300"
                    >
                        <Twitter className="mr-2" size={24}/>
                        Follow us on Twitter
                    </a>
                </section>
            </main>

            <footer className="bg-black text-purple-300 py-8">
                <div className="container mx-auto px-4 text-center">
                    <p className="mb-2">&copy; 2024 Jupara. All rights reserved.</p>
                    <div className="space-x-4">
                        <a
                            href="/privacy_policy.pdf"
                            className="hover:text-purple-100 transition-colors"
                        >
                            Privacy Policy
                        </a>
                        <a
                            href="/user_agreement.pdf"
                            className="hover:text-purple-100 transition-colors"
                        >
                            User Agreement
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default App;
